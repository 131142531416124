import React from "react";
import ReactDOM from "react-dom";

import Routes from "./Router";

import { CssBaseline } from "@material-ui/core";
import { SnackbarProvider } from "notistack";

ReactDOM.render(
    <>
        <CssBaseline />
        <SnackbarProvider>
            <Routes />
        </SnackbarProvider>
    </>,
    document.getElementById("root")
);
