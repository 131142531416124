import React, { useState, useEffect, useCallback, useContext } from "react";

//Context
import { ContextCreate } from "../../Auth/Context";

//Librerias
import axios from "axios";

//componentes Material-UI
import {
    AppBar,
    Tabs,
    Tab,
    Box,
    Grid,
    // Typography,
    // CircularProgress,
    useTheme,
    useMediaQuery,
} from "@material-ui/core";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";

//componentes
import Trofeos from "./components/trofeos";

//Imagenes
import BandaTrofeos from "../../static/images/SalaTrofeos/Felicitaciones.png";

const salaStyle = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
    borderShadow: {
        boxShadow: "0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12)",
    },
    imgMovil: {
        [theme.breakpoints.down("sm")]: {
            height: 70,
            width: 350,
            marginBottom: 10,
        },
    },
    gridAppBar: {
        position: "relative",
    },
    boxAppBar: {
        position: "absolute",
        top: 0,
        right: 0,
        paddingRight: 25,
        paddingTop: 5,
        [theme.breakpoints.down("sm")]: {
            top: -45,
            paddingRight: 0,
            paddingTop: 0,
        },
    },
    box: {
        paddingTop: "10px",
        [theme.breakpoints.down("sm")]: {
            height: "100%",
            paddingTop: "0px",
        },
    },
}));
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={3}>{children}</Box>}
        </div>
    );
}
const SalaTrofeo = () => {
    //========================================================================================================================
    //========================================= Context  =====================================================================
    //========================================================================================================================
    const authContext = useContext(ContextCreate);
    const { token, strData } = authContext;
    //========================================================================================================================
    //========================================= Hooks  =======================================================================
    //========================================================================================================================
    const theme = useTheme();
    const movil = useMediaQuery(theme.breakpoints.down("sm"));
    //========================================================================================================================
    //========================================= States  ======================================================================
    //========================================================================================================================
    const classes = salaStyle();
    const [value, setValue] = useState(0);
    const [data, setData] = useState({
        intHacer: 0,
        intMeta: 0,
        intCompañia: 0,
        dataReconocimientos: [],
        intPuntos: 0,
    });
    const [dataTable, setDataTable] = useState({
        tableHacer: [],
        tableMeta: [],
        tableCompañia: [],
    });
    const [collapseTable, setCollapseTable] = useState(false);
    const [tipoReconocimiento, setTipoReconocimiento] = useState();
    const [error, setError] = useState({
        flag: false,
        msg: "",
    });

    const [loading, setLoading] = useState(false);
    //========================================================================================================================
    //========================================= Funciones  ===================================================================
    //========================================================================================================================
    const getData = useCallback(
        async (signalCancelGetData, correo) => {
            setLoading(true);
            await axios({
                method: "GET",
                baseURL: `${process.env.REACT_APP_PROTOCOL_BACKEND}://${process.env.REACT_APP_HOST_BACKEND}${process.env.REACT_APP_PORT_BACKEND}`,
                url: `${process.env.REACT_APP_API_GETRECONOCIMIENTO}`,
                params: {
                    strEmailColaborador: correo,
                },
                headers: {
                    token,
                },
                cancelToken: signalCancelGetData.token,
            })
                .then((res) => {
                    if (res.data.error) {
                        throw new Error(res.data.msg);
                    }

                    setError({
                        flag: false,
                        msg: undefined,
                    });

                    setData((prevState) => ({
                        ...prevState,
                        dataReconocimientos: res.data.data,
                    }));

                    setLoading(false);
                })
                .catch((error) => {
                    if (!axios.isCancel(error)) {
                        setError({
                            flag: true,
                            msg: error.message
                                ? error.message
                                : "Ha ocurrido un error inesperado",
                        });
                        setLoading(false);
                    }
                });
        },
        [token]
    );

    const handelCollapseTable = (option) => {
        if (option === 1) {
            setTipoReconocimiento(1);
        }
        if (option === 2) {
            setTipoReconocimiento(2);
        }
        if (option === 3) {
            setTipoReconocimiento(3);
        }
        if (option !== tipoReconocimiento) {
            setCollapseTable(true);
        } else {
            setCollapseTable(false);
            setTipoReconocimiento(0);
        }
    };

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    //========================================================================================================================
    //========================================= useEffect  ===================================================================
    //========================================================================================================================
    useEffect(() => {
        const signalCancelGetData = axios.CancelToken.source();
        if (strData) {
            getData(signalCancelGetData, strData.strEmail);
        } else {
            signalCancelGetData.cancel("Petición abortada.");
        }
    }, [getData, strData]);

    useEffect(() => {
        let contadorHacer = 0;
        let contadorMeta = 0;
        let contadorCompañia = 0;
        let contadorPuntos = 0;
        let dataTableCompañia = [];
        let dataTableMeta = [];
        let dataTableHacer = [];
        let arrayReconocimientos = data.dataReconocimientos;

        for (let i = 0; i < arrayReconocimientos.length; i++) {
            let intMotivo = arrayReconocimientos[i].Reconocimiento;

            if (intMotivo === "Hacer bien su trabajo") {
                contadorHacer = contadorHacer + 1;
                dataTableHacer.push(arrayReconocimientos[i]);
                contadorPuntos = contadorPuntos + arrayReconocimientos[i].valor;
            }
            if (intMotivo === "Superar la meta") {
                contadorMeta = contadorMeta + 1;
                dataTableMeta.push(arrayReconocimientos[i]);
                contadorPuntos = contadorPuntos + arrayReconocimientos[i].valor;
            }
            if (intMotivo === "Llevar a la compañia a un crecimiento exponencial") {
                contadorCompañia = contadorCompañia + 1;
                dataTableCompañia.push(arrayReconocimientos[i]);
                contadorPuntos = contadorPuntos + arrayReconocimientos[i].valor;
            }
        }

        setData((prevState) => ({
            ...prevState,
            intHacer: contadorHacer,
            intMeta: contadorMeta,
            intCompañia: contadorCompañia,
            intPuntos: contadorPuntos,
        }));
        setDataTable((prevState) => ({
            ...prevState,
            tableHacer: dataTableHacer,
            tableMeta: dataTableMeta,
            tableCompañia: dataTableCompañia,
        }));
    }, [data.dataReconocimientos]);

    //========================================================================================================================
    //========================================= Renders  =====================================================================
    //========================================================================================================================
    return (
        <>
            <Box display="flex" justifyContent="center" paddingTop="10px">
                <img
                    className={classes.imgMovil}
                    src={BandaTrofeos}
                    alt="Bandita de felicitaciones"
                />
            </Box>

            <Box
                className={classes.box}
                display="flex"
                justifyContent="center"
                alignItems="center"
            >
                <Grid container>
                    <Grid item xs={12} className={classes.gridAppBar}>
                        <AppBar position="static">
                            <Tabs
                                value={value}
                                onChange={handleChange}
                                aria-label="simple tabs example"
                            >
                                <Tab label="Sala de Trofeos" {...a11yProps(0)} />
                            </Tabs>
                        </AppBar>
                        <Box
                            display="flex"
                            flexDirection="row"
                            justifyContent="center"
                            alignItems="center"
                            className={classes.boxAppBar}
                        >
                            {/* {loading ? (
                                <Box style={{ paddingTop: 5 }}>
                                    <CircularProgress color="inherit" size={25} />
                                </Box>
                            ) : (
                                <>
                                    <Box paddingRight={1}>
                                        <img
                                            src={require("../../static/images/SalaTrofeos/Coin.png")}
                                            alt="coins"
                                            width="40px"
                                        />
                                    </Box>
                                    <Box style={{ marginTop: "-3px" }}>
                                        <Typography
                                            style={
                                                movil
                                                    ? { color: "#000000" }
                                                    : { color: "#FFFFFF" }
                                            }
                                        >
                                            Mis puntos:{data.intPuntos}
                                        </Typography>
                                    </Box>
                                </>
                            )} */}
                        </Box>

                        <TabPanel
                            value={value}
                            index={0}
                            className={classes.borderShadow}
                        >
                            <Box display="flex" justifyContent="center">
                                <Trofeos
                                    data={data}
                                    dataTable={dataTable}
                                    collapseTable={collapseTable}
                                    tipoReconocimiento={tipoReconocimiento}
                                    error={error}
                                    loading={loading}
                                    movil={movil}
                                    handelCollapseTable={handelCollapseTable}
                                />
                            </Box>
                        </TabPanel>
                    </Grid>
                </Grid>
            </Box>
        </>
    );
};

export default SalaTrofeo;
