import React from "react";

//Componentes Material-UI
import {
    Card,
    CardActionArea,
    CardMedia,
    Typography,
    Box,
    CircularProgress,
    Grid,
    Fab,
} from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";

//Estilos Material-UI
import { makeStyles } from "@material-ui/core/styles";

//Componentes
import TableReconocimiento from "./getReconocimientos";

//Imagenes
import Compañia from "../../../../static/images/SalaTrofeos/TrofeoPodio.png";
import Meta from "../../../../static/images/SalaTrofeos/MedallaPodio.png";
import Hacer from "../../../../static/images/SalaTrofeos/HacerPodio.png";

const salaStyle = makeStyles((theme) => ({
    grid: {
        [theme.breakpoints.down("sm")]: {
            padding: "12px 0px",
        },
    },
    imageCard: {
        width: 373,
        height: 450,
        [theme.breakpoints.down("sm")]: {
            width: 280,
            height: 365,
        },
    },
    fab: {
        position: "absolute",
        width: 65,
        height: 65,
        top: theme.spacing(1),
        right: theme.spacing(1),
        [theme.breakpoints.down("sm")]: {
            position: "absolute",
            width: 56,
            height: 56,
            top: theme.spacing(1),
            right: theme.spacing(1),
        },
    },
    typography: {
        fontSize: 26,
        [theme.breakpoints.down("sm")]: {
            fontSize: 22,
        },
    },
    container: {
        width: 1024,
    },
}));

const Trofeos = ({
    data,
    dataTable,
    collapseTable,
    tipoReconocimiento,
    error,
    loading,
    handelCollapseTable,
    movil,
}) => {
    //========================================================================================================================
    //========================================= Renders  =====================================================================
    //========================================================================================================================
    const classes = salaStyle();
    if (loading) {
        return (
            <Box display="flex" justifyContent="center">
                <CircularProgress color="primary" size={45} />
            </Box>
        );
    }
    if (error.flag) {
        return (
            <Box display="flex" justifyContent="center">
                <Alert severity="error">
                    <AlertTitle>{error.msg}</AlertTitle>
                    Ha ocurrido un error al momento de cargar los datos de Sala de
                    trofeos.
                </Alert>
            </Box>
        );
    }

    return (
        <Grid container direction="row" justify="center" className={classes.container}>
            {movil ? (
                <Grid item xs={12} md={4} className={classes.grid}>
                    <Card>
                        <CardActionArea>
                            <Fab className={classes.fab} color="primary">
                                <Typography className={classes.typography}>
                                    {data.intCompañia}{" "}
                                </Typography>
                            </Fab>
                            <Box display="flex" justifyContent="center">
                                <CardMedia
                                    className={classes.imageCard}
                                    image={Compañia}
                                />
                            </Box>
                        </CardActionArea>
                    </Card>
                </Grid>
            ) : null}
            <Grid item xs={12} md={4} className={classes.grid}>
                <Card>
                    <CardActionArea onClick={() => handelCollapseTable(2)}>
                        <Fab className={classes.fab} color="primary">
                            <Typography className={classes.typography}>
                                {data.intMeta}
                            </Typography>
                        </Fab>
                        <Box display="flex" justifyContent="center">
                            <CardMedia className={classes.imageCard} image={Meta} />
                        </Box>
                    </CardActionArea>
                </Card>
            </Grid>

            {movil ? null : (
                <Grid item xs={12} md={4} className={classes.grid}>
                    <Card>
                        <CardActionArea onClick={() => handelCollapseTable(3)}>
                            <Fab className={classes.fab} color="primary">
                                <Typography className={classes.typography}>
                                    {data.intCompañia}{" "}
                                </Typography>
                            </Fab>
                            <Box display="flex" justifyContent="center">
                                <CardMedia
                                    className={classes.imageCard}
                                    image={Compañia}
                                />
                            </Box>
                        </CardActionArea>
                    </Card>
                </Grid>
            )}

            <Grid item xs={12} md={4} className={classes.grid}>
                <Card>
                    <CardActionArea onClick={() => handelCollapseTable(1)}>
                        <Fab className={classes.fab} color="primary">
                            <Typography className={classes.typography}>
                                {data.intHacer}
                            </Typography>
                        </Fab>
                        <Box display="flex" justifyContent="center">
                            <CardMedia className={classes.imageCard} image={Hacer} />
                        </Box>
                    </CardActionArea>
                </Card>
            </Grid>
            <Grid item xs={12}>
                {movil ? null : (
                    <TableReconocimiento
                        open={collapseTable}
                        dataTable={dataTable}
                        tipoReconocimiento={tipoReconocimiento}
                    />
                )}
            </Grid>
        </Grid>
    );
};

export default Trofeos;
