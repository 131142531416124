import React, {
    memo,
    useCallback,
    Fragment,
    useContext,
    useState,
    useEffect,
} from "react";

//Context
import { ContextCreate } from "../../Auth/Context";

//Librerias
import { Link, useHistory } from "react-router-dom";
//Componentes Material-UI
import {
    AppBar,
    Toolbar,
    Menu,
    MenuItem,
    Avatar,
    IconButton,
    Hidden,
    Typography,
    Box,
    Container,
} from "@material-ui/core";

//Iconos Material-UI
import { Menu as MenuIcon, PersonOutline as PersonIcon } from "@material-ui/icons";

//Style Material-UI
import { makeStyles, ThemeProvider, createMuiTheme } from "@material-ui/core/styles";

//Mis componentes
import MenuDrawer from "./drawerMenu";

//Estilos de mi componente
const themeOptions = createMuiTheme({
    palette: {
        type: "dark",
        primary: {
            main: "#3f51b5",
            contrastText: "#ffffff",
        },
        secondary: {
            main: "#f50057",
        },
        background: {
            default: "#ffffff",
            paper: "#424242",
        },
    },
});
const MenuStyle = makeStyles((theme) => ({
    avatarInterno: {
        width: "90px",
        height: "90px",
    },
    avatar: {
        marginLeft: "15px",
        width: "30px",
        height: "30px",
    },
    appBar: {
        transition: theme.transitions.create(["margin", "width"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarMenuActive: {
        [theme.breakpoints.up("sm")]: {
            width: `calc(100% - 15rem)`,
            marginLeft: "15rem",
            transition: theme.transitions.create(["margin", "width"], {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },
    },
    main: {
        display: "flex",
        position: "relative",
        flexGrow: 1,
        top: "56px",
        marginLeft: "0",
        transition: theme.transitions.create("margin", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    mainMenuActive: {
        top: "56px",
        display: "flex",
        position: "relative",
        flexGrow: 1,
        [theme.breakpoints.up("sm")]: {
            transition: theme.transitions.create("margin", {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
            marginLeft: "15rem",
        },
    },
    link: {
        textDecoration: "none",
        color: "white",
    },
    toolbarHeader: {
        minHeight: "45px",
    },
}));

/**
 * Componente con el cual se renderiza el menu de navegación de mi aplicativo donde se controla los permisos
 * @type {Function} menu de mi aplicativo
 * @param {children} children recibe el hijo para poder renderizar
 * @returns retorna el container donde se renderizaran los diferentes componentes de mi aplicativo
 */

const ContainerMenu = ({ children }) => {
    //===============================================================================================================================================
    //========================================== Context ============================================================================================
    //===============================================================================================================================================
    const { strData, cerrarSesion } = useContext(ContextCreate);

    //===============================================================================================================================================
    //========================================== States =============================================================================================
    //===============================================================================================================================================
    const [openMenu, setOpenMenu] = useState(false);
    const [openMenuProfile, setOpenMenuProfile] = useState(false);
    const [anchorEl, setAnchorEl] = useState();
    const [selectedList, setSelectedList] = useState(0);
    const [isAdmin, setIsAdmin] = useState();
    //===============================================================================================================================================
    //========================================== Hooks ==============================================================================================
    //===============================================================================================================================================
    let history = useHistory();
    //===============================================================================================================================================
    //========================================== Funciones ============================================================================================
    //===============================================================================================================================================

    const toggleDrawer = useCallback((e, flag) => {
        if (e.type === "keydown" && (e.key === "Tab" || e.key === "Shift")) {
            return;
        }
        setOpenMenu(flag);
    }, []);

    const toggleProfile = useCallback(() => {
        setOpenMenuProfile(!openMenuProfile);
    }, [openMenuProfile]);

    const handleProfile = useCallback((e) => {
        setAnchorEl(e.currentTarget);
    }, []);

    const cambiarInfo = useCallback(
        (url, index) => {
            history.push(url);
            setSelectedList(index);
        },
        [history]
    );

    useEffect(() => {
        if (strData.strRolApp) {
            for (let i = 0; i < strData.strRolApp.length; i++) {
                if (strData.strRolApp[i].strNombre === "ADMINISTRADOR") {
                    setIsAdmin(true);
                    return;
                }
            }
        }
    }, [strData]);

    //===============================================================================================================================================
    //========================================== Renders ============================================================================================
    //===============================================================================================================================================
    const nombreUsuario = `${strData.strNombre} ${strData.strApellidos}`;
    const classes = MenuStyle();

    return (
        <Fragment>
            <ThemeProvider theme={themeOptions}>
                <AppBar className={!openMenu ? classes.appBar : classes.appBarMenuActive}>
                    <Toolbar className={classes.toolbarHeader}>
                        <Box flexGrow={1} display="flex" alignItems="center">
                            {!openMenu ? (
                                <IconButton onClick={(e) => toggleDrawer(e, true)}>
                                    <MenuIcon style={{ color: "#ffffff" }} />
                                </IconButton>
                            ) : null}
                            <Typography variant="h6">
                                <Link
                                    to="/reconocimientos/inicio"
                                    className={classes.link}
                                    onClick={() =>
                                        cambiarInfo("/reconocimientos/inicio", 0)
                                    }
                                >
                                    De 10m a 10X
                                </Link>
                            </Typography>
                        </Box>
                        <Box display="flex" justifyContent="center" alignItems="center">
                            <Hidden smDown>
                                <Typography variant="subtitle1">
                                    {strData ? strData.strUsuario : "undefined"}
                                </Typography>
                            </Hidden>
                            <IconButton
                                style={{ padding: "0px" }}
                                onClick={(e) => {
                                    toggleProfile();
                                    handleProfile(e);
                                }}
                            >
                                <Avatar className={classes.avatar}>
                                    <PersonIcon />
                                </Avatar>
                            </IconButton>
                            <Menu
                                anchorEl={anchorEl}
                                open={openMenuProfile}
                                onClose={toggleProfile}
                            >
                                <Box
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="center"
                                    padding="0px 15px"
                                >
                                    <Box>
                                        <Avatar className={classes.avatarInterno}>
                                            <PersonIcon
                                                className={classes.avatarInterno}
                                            />
                                        </Avatar>
                                    </Box>
                                    <Box
                                        display="flex"
                                        flexDirection="column"
                                        justifyContent="center"
                                    >
                                        <Box padding="6px 16px" maxWidth="200px">
                                            <Typography noWrap>
                                                <b>
                                                    {strData
                                                        ? nombreUsuario
                                                        : "undefined"}
                                                </b>
                                            </Typography>
                                            <Typography variant="caption" noWrap>
                                                {strData ? strData.strEmail : undefined}
                                            </Typography>
                                        </Box>
                                        <Box>
                                            <MenuItem onClick={toggleProfile}>
                                                <a
                                                    href="https://choucairtesting-my.sharepoint.com/person.aspx"
                                                    rel="noopener noreferrer"
                                                    style={{
                                                        textDecoration: "none",
                                                        color: "black",
                                                    }}
                                                    target="_blank"
                                                >
                                                    Mi perfil de Office 365
                                                </a>
                                            </MenuItem>
                                            <MenuItem onClick={() => cerrarSesion()}>
                                                Cerrar sesión
                                            </MenuItem>
                                        </Box>
                                    </Box>
                                </Box>
                            </Menu>
                        </Box>
                    </Toolbar>
                </AppBar>
                <MenuDrawer
                    isAdmin={isAdmin}
                    open={openMenu}
                    toggleDrawer={toggleDrawer}
                    cambiarInfo={cambiarInfo}
                    selectedList={selectedList}
                />
            </ThemeProvider>
            <main className={!openMenu ? classes.main : classes.mainMenuActive}>
                <Container>{children}</Container>
            </main>
        </Fragment>
    );
};

export default memo(ContainerMenu);
