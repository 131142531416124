import React, { memo, useEffect, useState, useContext } from "react";

//Context
import { ContextCreate } from "../../Auth/Context";

//librerias
import { useHistory } from "react-router-dom";

//Componentes Material-UI
import {
    Grid,
    Typography,
    Card,
    CardHeader,
    CardActionArea,
    CardMedia,
    CardContent,
    useTheme,
    useMediaQuery,
} from "@material-ui/core";

//Estilos material-UI
import { makeStyles } from "@material-ui/core/styles";

//Componentes Lotti-Player
import { Player } from "@lottiefiles/react-lottie-player";

const inicioStyle = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(3),
        minHeight: "inherit",
    },
    container: {
        position: "relative",
        display: "flex",
        height: "80vh",
    },
    item: {
        flex: 1,
        position: "relative",
    },
}));

const Inicio = () => {
    //===============================================================================================================================================
    //========================================== Context ============================================================================================
    //===============================================================================================================================================
    const { strData } = useContext(ContextCreate);
    //===============================================================================================================================================
    //========================================== Hooks personalizados ===============================================================================
    //===============================================================================================================================================
    const history = useHistory();

    const theme = useTheme();
    const movil = useMediaQuery(theme.breakpoints.down("sm"));

    const [isAdmin, setIsAdmin] = useState();

    useEffect(() => {
        if (strData.strRolApp) {
            for (let i = 0; i < strData.strRolApp.length; i++) {
                if (strData.strRolApp[i].strNombre === "ADMINISTRADOR") {
                    setIsAdmin(true);
                    return;
                }
            }
        }
    }, [strData]);
    //===============================================================================================================================================
    //==================================================== Renders ==================================================================================
    //===============================================================================================================================================
    const classes = inicioStyle();

    return (
        <Grid
            container
            direction="row"
            alignItems="center"
            spacing={2}
            className={classes.container}
        >
            <Grid item xs={12}>
                <Grid
                    container
                    direction="row"
                    alignItems="stretch"
                    justify="center"
                    spacing={2}
                >
                    {isAdmin ? (
                        <Grid item xs={12} lg={4}>
                            <Card style={{ height: "100%" }}>
                                <CardActionArea
                                    href="https://apps.powerapps.com/play/e97c4edf-711e-4d08-b5fb-c40d7e764908?tenantId=d240b2ce-2fed-49c3-9165-a84265e9cd33&source=portal&screenColor=rgba(1%2C%2060%2C%2096%2C%201)"
                                    rel="noopener noreferrer"
                                    target="_blank"
                                >
                                    <CardHeader
                                        title={<b>Hacer un Reconocimiento</b>}
                                        titleTypographyProps={{
                                            variant: "subtitle1",
                                            gutterBottom: true,
                                            align: "center",
                                            component: "h5",
                                        }}
                                    />
                                    <CardMedia>
                                        <Player
                                            autoplay={true}
                                            controls={false}
                                            src={require("../../static/json/Recono.json")}
                                            style={
                                                movil
                                                    ? { width: "150px" }
                                                    : { width: "300px" }
                                            }
                                        />
                                    </CardMedia>
                                    <CardContent>
                                        <Typography variant="body2" component="p">
                                            Realiza Reconocimientos a los colaboradores de
                                            tu grupo de trabajo
                                        </Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </Grid>
                    ) : null}

                    <Grid item xs={12} lg={4}>
                        <Card style={{ height: "100%" }}>
                            <CardActionArea
                                onClick={() => {
                                    history.push("/reconocimientos/salatrofeos");
                                }}
                            >
                                <CardHeader
                                    title={<b>Sala de Trofeos</b>}
                                    titleTypographyProps={{
                                        variant: "subtitle1",
                                        gutterBottom: true,
                                        align: "center",
                                        component: "h5",
                                    }}
                                />
                                <CardMedia>
                                    <Player
                                        autoplay={true}
                                        controls={false}
                                        src={require("../../static/json/Trofeo.json")}
                                        style={
                                            movil
                                                ? { width: "150px" }
                                                : { width: "300px" }
                                        }
                                    />
                                </CardMedia>
                                <CardContent>
                                    <Typography variant="body2" component="p">
                                        Mira todos los reconocimientos que has tenido en
                                        forma de trofeos
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </Grid>
                    {isAdmin ? (
                        <Grid item xs={12} lg={4}>
                            <Card style={{ height: "100%" }}>
                                <CardActionArea
                                    href="https://apps.powerapps.com/play/e97c4edf-711e-4d08-b5fb-c40d7e764908?tenantId=d240b2ce-2fed-49c3-9165-a84265e9cd33&source=portal&screenColor=rgba(1%2C%2060%2C%2096%2C%201)"
                                    rel="noopener noreferrer"
                                    target="_blank"
                                >
                                    <CardHeader
                                        title={<b>Reconocimientos entregados</b>}
                                        titleTypographyProps={{
                                            variant: "subtitle1",
                                            gutterBottom: true,
                                            align: "center",
                                            component: "h5",
                                        }}
                                    />
                                    <CardMedia>
                                        <Player
                                            autoplay={true}
                                            controls={false}
                                            src={require("../../static/json/CheckRecono.json")}
                                            style={
                                                movil
                                                    ? { width: "150px" }
                                                    : { width: "300px" }
                                            }
                                        />
                                    </CardMedia>
                                    <CardContent>
                                        <Typography variant="body2" component="p">
                                            Mira los reconocimientos que has hecho a tu
                                            equipo de trabajo
                                        </Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </Grid>
                    ) : null}
                </Grid>
            </Grid>
        </Grid>
    );
};

export default memo(Inicio);
