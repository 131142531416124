import React, { memo } from "react";

//Librerias
import { Link } from "react-router-dom";

//componentes Material-UI
import {
    Drawer,
    Box,
    Divider,
    IconButton,
    useTheme,
    useMediaQuery,
} from "@material-ui/core";

//Style Material-UI
import { makeStyles, ThemeProvider, createMuiTheme } from "@material-ui/core/styles";

//Iconos de Material UI
import { ChevronLeft as ChevronLeftIcon } from "@material-ui/icons";

//Mis componentes
import ListAdmin from "./ListUser/AdminList";
import Version from "./docsVersion";

//Imagenes
import LogoCH from "../../static/images/logoChoucair.jpg";

const themeOptions = createMuiTheme({
    palette: {
        type: "dark",
        primary: {
            main: "#000000",
            contrastText: "#ffffff",
        },
        secondary: {
            main: "#f50057",
        },
        background: {
            default: "#ffffff",
            paper: "black",
        },
    },
});

const drawerStyles = makeStyles((theme) => ({
    bntCloseMenu: {
        minHeight: "63px",
        [theme.breakpoints.down("sm")]: {
            minHeight: "55px",
        },
    },
    drawerPaper: {
        backgroundColor: "black",
        color: "white",
    },
    box: {
        height: "inherit",
        width: "inherit",
    },
}));

/**
 *
 * @param {open} open es un booleano que controla si se abre el menuDrawer
 * @param {toggleDrawer} toggleDrawer es una funcion que me recibe dos parametros un event y un booleano
 *
 */

const MenuDrawer = ({ open, toggleDrawer, cambiarInfo, selectedList, isAdmin }) => {
    //===============================================================================================================================================
    //========================================== Declaracion de estados =============================================================================
    //===============================================================================================================================================

    //===============================================================================================================================================
    //========================================== Hooks personalizados ===============================================================================
    //===============================================================================================================================================
    const theme = useTheme();
    const movil = useMediaQuery(theme.breakpoints.down("sm"));

    //===============================================================================================================================================
    //============================================= UseEffect  ======================================================================================
    //===============================================================================================================================================

    //===============================================================================================================================================
    //========================================== Renders ============================================================================================
    //===============================================================================================================================================
    const classes = drawerStyles();
    return (
        <ThemeProvider theme={themeOptions}>
            <Drawer
                anchor="left"
                open={open}
                onClose={(e) => toggleDrawer(e, false)}
                classes={{ paper: classes.drawerPaper }}
                variant={movil ? "temporary" : "persistent"}
            >
                <Box display="flex" justifyContent="center">
                    <IconButton
                        onClick={(e) => toggleDrawer(e, false)}
                        className={classes.bntCloseMenu}
                    >
                        <ChevronLeftIcon />
                    </IconButton>
                </Box>
                <Divider />
                <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="space-between"
                    className={classes.box}
                    alignItems="center"
                >
                    <Box flexGrow={1}>
                        <Box display="flex" justifyContent="center">
                            <Link
                                to="/reconocimientos/inicio"
                                onClick={(e) => {
                                    if (movil) {
                                        toggleDrawer(e, false);
                                    }
                                    cambiarInfo("/reconocimientos/inicio", 0);
                                }}
                            >
                                <img
                                    src={LogoCH}
                                    alt="Icono choucair"
                                    style={{
                                        width: "150px",
                                        height: "45px",
                                        marginTop: "20px",
                                    }}
                                />
                            </Link>
                        </Box>
                        <ListAdmin
                            isAdmin={isAdmin}
                            index={selectedList}
                            toggleDrawer={toggleDrawer}
                            movil={movil}
                            cambiarInfo={cambiarInfo}
                        />
                    </Box>
                    <Box>
                        <Version />
                    </Box>
                </Box>
            </Drawer>
        </ThemeProvider>
    );
};

export default memo(MenuDrawer);
