import React from "react";

//Librerias
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

//Middlewares

//Componentes
import AuthState from "./Auth/Context";
import Login from "./containers/Login";
import AuthRouter from "./middleware/authRouter";
import Menu from "./containers/Menu";
import Inicio from "./containers/Home";
import Trofeos from "./components/SalaTrofeos"

const Routes = () => {
    return (
        <AuthState>
            <Router>
                <Switch>
                    <Route path="/" exact component={Login} />
                    <AuthRouter path="/reconocimientos">
                        <Menu>
                            <Switch>
                                <Route
                                    path="/reconocimientos/inicio"
                                    component={Inicio}
                                    exact
                                />
                                <Route
                                    path="/reconocimientos/salatrofeos"
                                    component={Trofeos}
                                    exact
                                />
                            </Switch>
                        </Menu>
                    </AuthRouter>
                </Switch>
            </Router>
        </AuthState>
    );
};

export default Routes;
