import React, { useState, useEffect } from "react";

// Componentes Material-UI
import { Box, Collapse } from "@material-ui/core";

//Estilos de Material UI
import { makeStyles } from "@material-ui/core/styles";

//Iconos de Material UI
import {
    ViewColumn as ViewColumnIcon,
    Edit as EditIcon,
    Clear as ClearIcon,
    DeleteOutline as DeleteOutlineIcon,
    Search as SearchIcon,
    SaveAlt as SaveAltIcon,
    ArrowDownward as ArrowDownwardIcon,
    ChevronLeft as ChevronLeftIcon,
    ChevronRight as ChevronRightIcon,
    FirstPage as FirstPageIcon,
    LastPage as LastPageIcon,
    Check as CheckIcon,
    FilterList as FilterListIcon,
    Remove as RemoveIcon,
    AddBox as AddBoxIcon,
} from "@material-ui/icons";

//Table Material-UI
import MaterialTable from "material-table";

const getReconocimientosStyles = makeStyles({
    table: {
        marginTop: "10px",
    },
});

const GetReconocimiento = ({ dataTable, loading, open, tipoReconocimiento }) => {
    const [data, setData] = useState([]);
    const [nombre, setNombre] = useState("");

    useEffect(() => {
        if (tipoReconocimiento === 1) {
            setData(dataTable.tableHacer);
            setNombre("Reconocimientos por Hacer bien tu trabajo");
        }
        if (tipoReconocimiento === 2) {
            setData(dataTable.tableMeta);
            setNombre("Reconocimientos por Superar la meta");
        }
        if (tipoReconocimiento === 3) {
            setData(dataTable.tableCompañia);
            setNombre(
                "Reconocimientos por Llevar la compañia a un crecimiento exponencial"
            );
        }
    }, [tipoReconocimiento, dataTable]);

    const classes = getReconocimientosStyles();
    return (
        <Collapse in={open}>
            <Box className={classes.table}>
                <MaterialTable
                    icons={{
                        Add: AddBoxIcon,
                        Clear: ClearIcon,
                        Check: CheckIcon,
                        Delete: DeleteOutlineIcon,
                        Edit: EditIcon,
                        DetailPanel: ChevronRightIcon,
                        Export: SaveAltIcon,
                        Filter: FilterListIcon,
                        FirstPage: FirstPageIcon,
                        LastPage: LastPageIcon,
                        NextPage: ChevronRightIcon,
                        PreviousPage: ChevronLeftIcon,
                        Search: SearchIcon,
                        ResetSearch: ClearIcon,
                        SortArrow: ArrowDownwardIcon,
                        ThirdStateCheck: RemoveIcon,
                        ViewColumn: ViewColumnIcon,
                    }}
                    localization={{
                        pagination: {
                            labelRowsSelect: "filas",
                            labelDisplayedRows: "{from}-{to} de {count}",
                            firstTooltip: "Primera página",
                            previousTooltip: "Página anterior",
                            nextTooltip: "Siguiente página",
                            lastTooltip: "Última página",
                        },
                        toolbar: {
                            nRowsSelected: "{0} filas seleccionadas",
                            searchTooltip: "Buscar",
                            searchPlaceholder: "Buscar",
                        },
                        header: {
                            actions: "Acciones",
                        },
                        body: {
                            emptyDataSourceMessage:
                                "No existen Reconocimientos de este tipo para mostrar.",
                            filterRow: {
                                filterTooltip: "Filtro",
                            },
                            editRow: {
                                deleteText: "Esta seguro de eliminar el registro?",
                            },
                        },
                        selector: {
                            okLabel: "aceptar",
                            cancelLabel: "Cancelar",
                            clearLabel: "Clear",
                            todayLabel: "Hoy",
                        },
                        grouping: {
                            placeholder:
                                "Arrasta el nombre de la columna para agrupar los campos",
                            groupedBy: "Datos agrupados por: ",
                        },
                    }}
                    columns={[
                        {
                            title: "Fecha del Reconocimiento",
                            field: "Fecha",
                            type: "date",
                        },
                        {
                            title: "Correo Lider ",
                            field: "Lider",
                            type: "string",
                        },
                        {
                            title: "Descripción",
                            field: "Comentarios",
                            type: "string",
                        },
                    ]}
                    isLoading={loading}
                    data={data}
                    title={nombre}
                    options={{
                        headerStyle: {
                            backgroundColor: "#616161",
                            color: "#FFFFFF",
                        },
                        search: false,
                        filtering: false,
                        toolbar: true,
                        showTitle: true,
                        actionsColumnIndex: 4,
                        grouping: false,
                    }}
                />
            </Box>
        </Collapse>
    );
};

export default GetReconocimiento;
