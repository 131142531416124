import React, { Fragment } from "react";

//Librerias
import { Link as RouterLink } from "react-router-dom";

//Componentes de Material UI
import { List, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";

//Iconos de Material UI
import { SettingsApplications as AdminIcon } from "@material-ui/icons";

//Estilos de Material UI
import { makeStyles } from "@material-ui/core/styles";

const listMenuUserStyles = makeStyles({
    list: {
        width: "15rem",
    },
    root: {
        "&$selected": {
            backgroundColor: "#606060",
        },
    },
    selected: {},
});

const GetListAdmin = ({ toggleDrawer, movil, index, cambiarInfo, isAdmin }) => {
    const classes = listMenuUserStyles();
    return (
        <Fragment>
            <List component="nav">
                <ListItem
                    button
                    component={RouterLink}
                    to="/reconocimientos/admin"
                    selected={index === 1}
                    disabled={true}
                    classes={{ selected: classes.selected, root: classes.root }}
                    className={classes.list}
                    onClick={(e) => {
                        if (movil) {
                            toggleDrawer(e, false);
                        }

                        cambiarInfo("/reconocimientos/admin", 1);
                    }}
                >
                    <ListItemIcon>
                        <AdminIcon
                            style={isAdmin ? { color: "white" } : { color: "black" }}
                            fontSize="large"
                        />
                    </ListItemIcon>
                    <ListItemText
                        style={isAdmin ? { color: "white" } : { color: "black" }}
                        primary="Gestionar Reconocimientos"
                    />
                </ListItem>
            </List>
        </Fragment>
    );
};

export default GetListAdmin;
