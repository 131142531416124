import React, { useState, createContext, memo } from "react";
//Librerias
import Cookies from "js-cookie";

export const ContextCreate = createContext();

/**
 * Componente con el cual se maneja la informacion glabal del aplicativo en cuanto a temas de autenticación
 * @type {Function} Contexto de mi aplicativo
 * @param {children} children recibe el hijo para poder envovler el aplicativo
 * @returns Provider que envuelve a mi aplicativo
 */

const ContextAPP = ({ children }) => {
    const [data, setData] = useState({
        token: localStorage.getItem("token") || Cookies.get("token") || undefined,
        autenticado: false,
        strData: "",
    });

    const iniciarSesion = (token) => {
        localStorage.setItem("token", token);
        Cookies.set("token", token);
        setData((prevState) => ({
            ...prevState,
            token,
        }));
    };

    const cerrarSesion = () => {
        setData((prevState) => ({
            ...prevState,
            token: null,
            strData: "",
        }));
        localStorage.removeItem("token");
        Cookies.remove("token");
    };

    const handlerChangeData = (type, value) => {
        setData((prevState) => ({
            ...prevState,
            [type]: value,
        }));
    };

    return (
        <ContextCreate.Provider
            value={{
                iniciarSesion,
                cerrarSesion,
                handlerChangeData,
                token: data.token,
                strData: data.strData,
            }}
        >
            {children}
        </ContextCreate.Provider>
    );
};

export default memo(ContextAPP);
