import React, { useState, useEffect, useCallback, useContext, memo, useRef } from "react";

//Context
import { ContextCreate } from "../../Auth/Context";

//Librerias
import { useForm, Controller } from "react-hook-form";
import { Player } from "@lottiefiles/react-lottie-player";
import { Redirect } from "react-router-dom";
import validator from "validator";
import axios from "axios";
import Cookies from "js-cookie";

//Componentes Material-UI
import {
    Grid,
    Box,
    Paper,
    TextField,
    Button,
    InputAdornment,
    Container,
    Typography,
    Hidden,
    LinearProgress,
    CircularProgress,
} from "@material-ui/core";
import { withSnackbar } from "notistack";
import { AccountCircle as AccountCircleIcon, Lock as LockIcon } from "@material-ui/icons";

//Estilos de Material UI
import { makeStyles } from "@material-ui/core/styles";

//Imagenes
import LoginWall from "../../static/images/loginWall.jpg";
import LogoCH from "../../static/images/LogoCH.png";

const containerLoginStyle = makeStyles((theme) => ({
    linearProgress: {
        position: "absolute",
        width: "100%",
        borderRadius: "10px 10px 0 0",
    },
    button: {
        position: "relative",
    },
    circularProgress: {
        position: "absolute",
        margin: "auto",
    },
    checked: {
        fontSize: "12px",
    },
    copyright: {
        position: "absolute",
        bottom: 0,
        left: 0,
        right: 0,
        marginRight: "auto",
        marginLeft: "auto",
        textAlign: "center",
    },
    gridOne: {
        padding: theme.spacing(3),
        position: "relative",
    },
    gridTwo: {
        backgroundColor: "#174060",
        borderRadius: "0 7px 7px 0",
    },
    player: {
        position: "relative",
        width: "750px",
        margin: "auto",
    },
    backgrounLogin: {
        backgroundImage: `url(${LoginWall})`,
        height: "100vh",
        width: "100vw",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        overflow: "hidden",
    },
    container: {
        display: "relative",
        height: "100vh",
    },
    paper: {
        position: "relative",
        borderRadius: "7px",
    },
}));
const ContainerLogin = ({ enqueueSnackbar }) => {
    //========================================================================================================================
    //========================================= Context  =====================================================================
    //========================================================================================================================
    const { token, iniciarSesion } = useContext(ContextCreate);
    //========================================================================================================================
    //========================================= hooks personalizados  ========================================================
    //========================================================================================================================
    const { control, errors, handleSubmit } = useForm({ mode: "onChange" });
    //=========================================================================================================================
    //========================================== Referencias ==================================================================
    //=========================================================================================================================
    const enqueueSnackbarRef = useRef(enqueueSnackbar);

    //========================================================================================================================
    //========================================= States  ======================================================================
    //========================================================================================================================
    const [loading, setLoading] = useState(false);
    const [flagSubmit, setFlagSubmit] = useState(false);
    const [data, setData] = useState({
        strUser: "",
        strPass: "",
        strApp: "RECONOCIMIENTOS",
    });

    //========================================================================================================================
    //========================================= Funciones  ===================================================================
    //========================================================================================================================

    const onSubmitData = (data) => {
        setData((prevState) => ({
            ...prevState,
            ...data,
        }));
        setFlagSubmit(true);
    };

    const submitData = useCallback(
        async (signalSubmitData) => {
            setLoading(true);
            setFlagSubmit(false);

            await axios(
                {
                    method: "POST",
                    baseURL: `${process.env.REACT_APP_PROTOCOL_BACKEND_DATALAKESERVICES}://${process.env.REACT_APP_HOST_BACKEND_DATALAKESERVICES}${process.env.REACT_APP_PORT_BACKEND_DATALAKESERVICES}`,
                    url: `${process.env.REACT_APP_API_LOGIN_DATALAKESERVICES}`,
                    data,
                },
                {
                    cancelToken: signalSubmitData.token,
                }
            )
                .then((res) => {
                    if (res.data.error) {
                        throw new Error(res.data.msg);
                    }
                    setLoading(false);
                    iniciarSesion(res.data.data);
                    if (
                        !process.env.REACT_APP_NODE_ENV ||
                        process.env.REACT_APP_NODE_ENV !== "production"
                    ) {
                        Cookies.set("token", res.data.data);
                    } else {
                        Cookies.set("token", res.data.data, {
                            domain: ".choucairtesting.com",
                        });
                    }
                })
                .catch((error) => {
                    if (!axios.isCancel(error)) {
                        console.error(error);
                        setLoading(false);
                        enqueueSnackbarRef.current(error.message, {
                            variant: "error",
                        });
                    }
                });
        },
        [data, iniciarSesion]
    );
    //===============================================================================================================================================
    //========================================== useEffects =========================================================================================
    //===============================================================================================================================================
    useEffect(() => {
        let signalSubmitData = axios.CancelToken.source();
        if (flagSubmit) {
            submitData(signalSubmitData);
        }
        return () => {
            signalSubmitData.cancel("Petición abortada.");
        };
    }, [flagSubmit, submitData]);

    //===============================================================================================================================================
    //========================================== Renders ============================================================================================
    //===============================================================================================================================================
    //#NOTE: variable que se utiliza para aplicar estilos personalizados de material ui
    const classes = containerLoginStyle();
    if (token) {
        return <Redirect to="/reconocimientos/inicio" />;
    }

    return (
        <div className={classes.backgrounLogin}>
            <Container className={classes.container}>
                <Box
                    height="inherit"
                    width="inherit"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                >
                    <Paper elevation={3} className={classes.paper}>
                        {loading ? (
                            <LinearProgress className={classes.linearProgress} />
                        ) : null}
                        <Grid container direction="row">
                            <Grid item xs={12} md={4} className={classes.gridOne}>
                                <form onSubmit={handleSubmit(onSubmitData)} noValidate>
                                    <Grid container direction="row" spacing={2}>
                                        <Grid item xs={12}>
                                            <Box display="flex" justifyContent="center">
                                                <img src={LogoCH} alt="Logo Choucair" />
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography
                                                variant="h5"
                                                component="div"
                                                align="center"
                                            >
                                                De 10m a 10X
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography
                                                variant="caption"
                                                component="div"
                                                align="center"
                                            >
                                                Reconoce la labor de tus colaboradores.
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Controller
                                                id="txt_strUser"
                                                name="strUser"
                                                defaultValue={data.strUser}
                                                render={(props) => (
                                                    <TextField
                                                        label="Usuario"
                                                        helperText={
                                                            errors.strUser
                                                                ? errors.strUser.message
                                                                : "Digita tu usuario corporativo, Ejemplo: soporteti"
                                                        }
                                                        error={
                                                            errors.strUser ? true : false
                                                        }
                                                        fullWidth
                                                        required
                                                        disabled={loading}
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <AccountCircleIcon />
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                        {...props}
                                                    />
                                                )}
                                                control={control}
                                                rules={{
                                                    required:
                                                        "Por favor digita tu usuario corporativo, Ejemplo: soporteti",
                                                    validate: (value) => {
                                                        let testValue = /^[-\w.%+]{1,41}@/i.test(
                                                            value
                                                        );
                                                        if (
                                                            !validator.isLength(value, {
                                                                min: 0,
                                                                max: 40,
                                                            })
                                                        ) {
                                                            return "El campo de usuario sobrepasa el limite de longitud permitida.";
                                                        }
                                                        if (testValue) {
                                                            return "Por favor utiliza solo tu usuario corporativo excluyendo el dominio @choucairtesting.com.";
                                                        }
                                                    },
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Controller
                                                id="txt_strPass"
                                                name="strPass"
                                                defaultValue={data.strPass}
                                                render={(props) => (
                                                    <TextField
                                                        label="Contraseña"
                                                        type="password"
                                                        helperText={
                                                            errors.strPass
                                                                ? errors.strPass.message
                                                                : "Digita tu contraseña"
                                                        }
                                                        error={
                                                            errors.strPass ? true : false
                                                        }
                                                        fullWidth
                                                        required
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <LockIcon />
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                        disabled={loading}
                                                        {...props}
                                                    />
                                                )}
                                                control={control}
                                                rules={{
                                                    required:
                                                        "Por favor digita tu contraseña",
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Button
                                                variant="contained"
                                                fullWidth
                                                color="primary"
                                                type="submit"
                                                disabled={loading}
                                                className={classes.button}
                                            >
                                                ingresar
                                                {loading ? (
                                                    <CircularProgress
                                                        className={
                                                            classes.circularProgress
                                                        }
                                                        size={24}
                                                    />
                                                ) : null}
                                            </Button>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography
                                                variant="caption"
                                                component="div"
                                                align="center"
                                            >
                                                <a
                                                    href="https://corporativo.choucairtesting.com/mesadeayuda/"
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    ¿Olvidaste tu contraseña?
                                                </a>
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </form>
                                <Box className={classes.copyright}>
                                    <Box>
                                        <Typography variant="caption">
                                            Todos los derechos reservados - 2020 ©
                                        </Typography>
                                    </Box>
                                </Box>
                            </Grid>
                            <Hidden smDown>
                                <Grid item xs={8} className={classes.gridTwo}>
                                    <Box className={classes.player}>
                                        <Player
                                            autoplay={true}
                                            controls={false}
                                            loop={true}
                                            src={require("../../static/json/teamlogin.json")}
                                        />
                                    </Box>
                                </Grid>
                            </Hidden>
                        </Grid>
                    </Paper>
                </Box>
            </Container>
        </div>
    );
};

export default withSnackbar(memo(ContainerLogin));
